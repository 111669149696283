import React, { FC } from 'react';
import './AnchorNav.scss';

const AnchorNav: FC<any> = ({ component }) => (
  <div className="expert-landing-page__anchornav">
    <a className="expert-landing-page__anchorlink" href="#benefits">
      {component.benefitsLink}
    </a>
    <a className="expert-landing-page__anchorlink" href="#howto">
      {component.howToLink}
    </a>
    <a className="expert-landing-page__anchorlink" href="#range">
      {component.rangeLink}
    </a>
  </div>
);

export default AnchorNav;
