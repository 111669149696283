import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import UmbracoImage from 'common/Image/UmbracoImage/UmbracoImage';
import Image from 'common/Image';

import './Range.scss';
import BazaarVoice from 'common/BazaarVoice';

const ExpertRange: React.FC<any> = ({ component, products }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [showShops, setShowShops] = useState({}); // State variable to track if "shops-block" should be shown for each product

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1587,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 1184,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleBuyNowClick = (id) => {
    setShowShops((prevShowShops) => ({
      ...prevShowShops,
      [id]: !prevShowShops[id], // Toggle the state for the specific product
    }));
  };

  return (
    <div className="expert-landing-page-range expert-landing-page__container" id="range">
      <div className="expert-landing-page-range__title">{component.title}</div>
      <div className="expert-landing-page-range__sub-title">{component.subTitle}</div>

      <div className="expert-landing-page-range__products">
        <div className="expert-landing-page-range__carousel">
          {hasMounted && (
            <Slider {...settings}>
              {products.map((product) => (
                <div className="expert-landing-page-range__slide" key={product.id}>
                  <div className="expert-landing-page-range__product">
                    <Image
                      className="expert-landing-page-range__product-img"
                      localImage={product.localProductImage}
                      alt={product.productTitle}
                    />

                    <div className="expert-landing-page-range__product-name">
                      {product.productTitle}
                    </div>
                    <div className="expert-landing-page-range__product-description">
                      {product.productDescriptionShort}
                    </div>

                    <button
                      type="button"
                      className="expert-landing-page-range__product-cta"
                      onClick={() => handleBuyNowClick(product.id)}
                    >
                      BUY NOW
                    </button>
                    <BazaarVoice
                      eanCode={product.productEAN}
                      inlineRating
                      productURL={product.url}
                    />
                    {showShops[product.id] && (
                      <div className="expert-landing-page-range__shops-block">
                        {product.shops.map((shop) => (
                          <a
                            href={shop.url}
                            className="expert-landing-page-range__product-shop"
                            target="_blank"
                            rel="noopener noreferrer"
                            key={shop.id}
                          >
                            <UmbracoImage
                              className="expert-landing-page-range__shop-image"
                              image={shop.shopImage}
                            />
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
      <DangerouslySetInnerHtml
        className="expert-landing-page-range__content"
        html={component.content}
      />
    </div>
  );
};

export default ExpertRange;
