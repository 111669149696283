import React, { FC } from 'react';
import UmbracoImage from 'common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';

import './Benefits.scss';

const Benefits: FC<any> = ({ component }) => (
  <div className="expert-landing-page__container expert-landing-page-benefits" id="benefits">
    <div className="expert-landing-page-benefits__header">
      <h1 className="expert-landing-page-benefits__title">{component.title}</h1>
      <div className="expert-landing-page-benefits__sub-title">{component.subTitle}</div>
    </div>
    <div className="expert-landing-page-benefits__row">
      <div className="expert-landing-page-benefits__packshot">
        <UmbracoImage lazyLoad image={component.packshot} />
      </div>
      <div className="expert-landing-page-benefits__block expert-landing-page-benefits__block--left">
        <div className="expert-landing-page-benefits__block-title">
          {component.benefitsLeft.title}
        </div>
        <div className="expert-landing-page-benefits__list">
          {component.benefitsLeft.icons.map((benefit: any) => (
            <div className="expert-landing-page-benefits__benefit">
              <div className="expert-landing-page-benefits__icon">
                <UmbracoImage lazyLoad image={benefit.image} />
              </div>
              <DangerouslySetInnerHtml
                className="expert-landing-page-benefits__text"
                html={benefit.text}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="expert-landing-page-benefits__block  expert-landing-page-benefits__block--right">
        <div className="expert-landing-page-benefits__block-title">
          {component.benefitsRight.title}
        </div>
        <div className="expert-landing-page-benefits__list">
          {component.benefitsRight.icons.map((benefit: any) => (
            <div className="expert-landing-page-benefits__benefit">
              <div className="expert-landing-page-benefits__icon">
                <UmbracoImage lazyLoad image={benefit.image} />
              </div>
              <DangerouslySetInnerHtml
                className="expert-landing-page-benefits__text"
                html={benefit.text}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    <a className="expert-landing-page-benefits__cta" href="#range">
      {component.cta.text}
    </a>
  </div>
);

export default Benefits;
