import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from 'layout/Layout';

import './expertLandingPage.scss';
import './Layout.scss';

import Seo from 'common/Seo';
import { getLangString } from 'utils/functions';
import Benefits from './Benefits';
import Banner from './Banner';
import AnchorNav from './AnchorNav';
import ExpertVideos from './Videos';
import ExpertRange from './Range';

const ExpertLandingPage: FC<any> = ({
  data: {
    allUmbracoExpertLandingPage: { nodes },
    allSalsifyProducts: { nodes: salsifyProducts },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    bannerSection,
    anchorLinksSection,
    benefitsSection,
    videoSection,
    rangeSection,
  } = nodes[0];

  const [bazaarVoice, setBazaarVoice] = useState(
    process.env.GATSBY_BAZAAR_VOICE_URL
      ? process.env.GATSBY_BAZAAR_VOICE_URL.replace('{lang}', getLangString(lang))
      : ''
  );

  useEffect(() => {
    process.env.GATSBY_BAZAAR_VOICE_URL &&
      setBazaarVoice(process.env.GATSBY_BAZAAR_VOICE_URL!.replace('{lang}', getLangString(lang)));
  }, [lang]);

  // Get rangeTag from rangeSection
  const rangeTag = parseInt(rangeSection.rangeTag, 10);

  // Filter salsifyProducts based on the rangeTag
  const filteredSalsifyProducts = salsifyProducts.filter((product) =>
    product.tags.some((tag) => tag.id === rangeTag)
  );

  return (
    <Layout className="expert-landing-page-layout" langProps={{ urls, lang }}>
      <Helmet>
        <script async src={bazaarVoice} type="text/javascript" />
      </Helmet>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <div className="expert-landing-page">
        <div className="expert-landing-page__block">
          <Banner component={bannerSection} />
        </div>
        <div className="expert-landing-page__block expert-landing-page__block--light">
          <AnchorNav component={anchorLinksSection} />
        </div>
        <div className="expert-landing-page__block expert-landing-page__block--dark">
          <Benefits component={benefitsSection} />
        </div>
        <div className="expert-landing-page__block">
          <ExpertVideos component={videoSection} />
        </div>
        <div className="expert-landing-page__block expert-landing-page__block--dark">
          <ExpertRange component={rangeSection} products={filteredSalsifyProducts} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ExpertLandingPageQuery($pageId: String) {
    allUmbracoExpertLandingPage(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }

        bannerSection {
          desktopVideo {
            url
          }
          mobileVideo {
            url
          }
          flourishLogo {
            ...umbracoImage
          }
          cta {
            text
            ariaLabel
            link {
              name
              target
              url
            }
          }
        }

        anchorLinksSection {
          benefitsLink
          howToLink
          rangeLink
        }

        benefitsSection {
          title
          subTitle
          packshot {
            ...umbracoImage
          }
          benefitsLeft {
            title
            icons {
              text
              image {
                ...umbracoImage
              }
            }
          }
          benefitsRight {
            title
            icons {
              text
              image {
                ...umbracoImage
              }
            }
          }
          cta {
            text
            ariaLabel
            link {
              name
              target
              url
            }
          }
        }

        videoSection {
          title
          subTitle
          videos {
            progressTitle
            title
            videoID
          }
          cta {
            text
            ariaLabel
            link {
              name
              target
              url
            }
          }
        }

        rangeSection {
          title
          subTitle
          rangeTag
          content
        }
      }
    }
    allBrandSettings {
      nodes {
        brandName
        url
        lang
        brandLogo {
          ...umbracoImage
        }
        customLogoRegex
        customBrandLogo {
          ...umbracoImage
        }
      }
    }
    socialMedia {
      socialMediaItems {
        properties {
          socialMediaIcon
          socialMediaAriaLabel
          isVisible
          socialMediaIconColor {
            label
            value
          }
          socialMediaHoverIconColor {
            label
            value
          }
          socialMediaLink {
            name
            target
            url
            icon
            published
          }
        }
      }
    }
    allSalsifyProducts(sort: { fields: updatedAt, order: ASC }) {
      nodes {
        id
        productEAN
        productTitle
        productTitleWithSize
        productSize
        productDescriptionMedium
        productDescriptionShort
        url
        productImage
        localProductImage {
          childImageSharp {
            fixed(width: 260, height: 260) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        productEAN
        productTitleShort
        updatedAt
        tags {
          id
        }
        shops {
          arialabel
          url
          shopImage {
            ...umbracoImage
          }
        }
      }
    }
  }
`;

export default ExpertLandingPage;
