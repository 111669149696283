import UmbracoImage from 'common/Image/UmbracoImage/UmbracoImage';
import React, { FC, useContext } from 'react';
import ScreenRecognitionContext from '../../utils/context/screenRecognitionContext';

import './Banner.scss';

const Banner: FC<any> = ({ component }) => {
  const { isMobile } = useContext(ScreenRecognitionContext);
  const video = isMobile ? component.mobileVideo : component.desktopVideo;

  return (
    <div className="expert-landing-page-banner">
      <video
        loop
        autoPlay
        muted
        playsInline
        className="expert-landing-page-banner__video"
        src={video.url}
      />
      <UmbracoImage className="expert-landing-page-banner__logo" image={component.flourishLogo} />
    </div>
  );
};

export default Banner;
