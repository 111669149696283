import React, { FC, useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Videos.scss';

const ExpertVideos: FC<any> = ({ component }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    beforeChange: (_current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1184,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleProgressTitleClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <div className="expert-landing-page-videos expert-landing-page__container" id="howto">
      <div className="expert-landing-page-videos__videos-carousel">
        <div className="expert-landing-page-videos__title">{component.title}</div>
        <div className="expert-landing-page-videos__subtitle">{component.subTitle}</div>
        <div className="expert-landing-page-videos__progress-bar">
          {component.videos.map((videoprog, innerIndex) => (
            <div
              key={videoprog.videoID}
              onClick={() => handleProgressTitleClick(innerIndex)}
              role="button"
              tabIndex={0}
              className={
                innerIndex === activeSlide
                  ? 'expert-landing-page-videos__progress-element active'
                  : 'expert-landing-page-videos__progress-element'
              }
            >
              <div
                className={
                  innerIndex === activeSlide
                    ? 'expert-landing-page-videos__progress-dot active'
                    : innerIndex < activeSlide
                    ? 'expert-landing-page-videos__progress-dot visited'
                    : 'expert-landing-page-videos__progress-dot'
                }
              />
              <div
                className={
                  innerIndex === activeSlide
                    ? 'expert-landing-page-videos__progress-title active'
                    : 'expert-landing-page-videos__progress-title'
                }
              >
                {videoprog.progressTitle}
              </div>
              {/* {//if last element, don't show line} */}
              {innerIndex !== component.videos.length - 1 && (
                <div
                  className={
                    innerIndex === activeSlide
                      ? 'expert-landing-page-videos__progress-line active'
                      : innerIndex < activeSlide
                      ? 'expert-landing-page-videos__progress-line visited'
                      : 'expert-landing-page-videos__progress-line'
                  }
                />
              )}
            </div>
          ))}
        </div>

        {hasMounted && (
          <Slider ref={sliderRef} {...settings}>
            {component.videos.map((video) => (
              <div key={video.videoID} className="expert-landing-page-videos__slide">
                <div className="expert-landing-page-videos__video">
                  <div className="expert-landing-page-videos__video-title">{video.title}</div>
                  <iframe
                    className="expert-landing-page-videos__frame"
                    src={`https://www.youtube-nocookie.com/embed/${video.videoID}`}
                    title={video.title}
                    allow-same-origin
                    loading="eager"
                  />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ExpertVideos;
